import { classNames } from '@/components/shared/classNames'
import Logo from './GlobalLynx.svg'
import styles from './GlobalLynx.module.scss'

export interface Props {
  className?: string
}

const GlobalLynx = ({ className = 'w-full h-auto' }: Props) => {
  return (
    <span className={classNames(className, 'inline', styles['global-lynx'])}>
      <span className="whitespace-nowrap">
        Global Lynx<sup>®</sup>
      </span>
      <Logo className={styles['global-lynx--logo']} />
    </span>
  )
}

export default GlobalLynx
